<template>
  <div class="wrap">
    <div class="data-header">
      <span class="data-min-title">{{cnName}}</span>
      <h1>最近一周统计：{{time}}</h1>
    </div>
    <div class="data-content">
      <!-- <h1>最近一周统计：{{time}}</h1> -->
      <ul class="data-list">
        <li v-for="(item,index) in newData" :key="index">
          <div class="img-box">
            <p class="person">{{item.count}}</p>
            <div class="bg-img" :class="'bg-img'+(index+1)"></div>
          </div>
          <p class="desc">{{item.alarmName}}</p>
          <p class="percentage">{{`（占比${item.percentage.toFixed()}%）`}}</p>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import mixin from "./mixin-all";
export default {
  mixins: [mixin],
  data() {
    return {
      //↓/////钩子字段//////////////
      name: "ryzybj",
      cnName: "人员作业报警",
      api: "/sanitation/dataCloudService/getPersonnelAlarmStatistics",
      isMock: false,
      comData: [
        {
          count: 0,
          alarmName: "暂无数据",
          percentage: 0
        }
      ],
      //↑////钩子字段//////////////
      debug: 0,
      time:
        this.$dayjs()
          .subtract(6, "day")
          .format("YYYY-MM-DD") +
        "——" +
        this.$dayjs().format("YYYY-MM-DD"),
      // 假数据
      personInfo: []
    };
  },
  computed: {
    newData() {
      let arr = this.comData.map(item => {
        return item.count;
      });

      if (arr > 0) {
        let sum = arr.reduce((total, num) => {
          return total + num;
        });
        this.log(sum);
        return this.comData.map(item => {
          return {
            count: item.count,
            alarmName: item.alarmName,
            percentage: (item.count / sum) * 100
          };
        });
      } else {
        return  this.comData
      }
    }
  },      
  mounted() {
    if (this.isMock) {
      let arr = isData().map(item => {
        return item.count;
      });
      let sum = arr.reduce((total, num) => {
        return total + num;
      });
      this.log(sum);
      this.comData = isData().map(item => {
        return {
          count: item.count,
          alarmName: item.alarmName,
          percentage: (item.count / sum) * 100
        };
      });
    }
  }
};
function isData() {
  let arr = [];
  for (let i = 1; i < 7; i++) {
    arr.push({
      count: 11 * i,
      alarmName: "作业里程" + i
    });
  }
  return arr;
}
</script>
<style lang="sass" scoped>

.wrap
  width: 100%
  height: 100%
.data-main .data-content
  padding: 20px 20px 0;
.data-header > h1
  position: absolute
  bottom: -25%
  width: 100%
  font-size: 0.8em;
  color: #fff;
  text-align: center;
.data-list
  width: 100%
  height: 100%
  display: flex
  flex-wrap: wrap
  & > li
    width: 33.33%
    height: 50%
    padding: 1% 2%
    box-sizing: border-box
    // background-color: #fff
    text-align: center;
    font-size: 20px;
    color: #fff;
    .img-box
      position: relative
      width: 100%
      height: 72%
      .bg-img
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
      .bg-img1
        background: url('../../../assets/images/cloud-hw/yuan1.png') no-repeat center/contain
      .bg-img2
        background: url('../../../assets/images/cloud-hw/yuan2.png') no-repeat center/contain
      .bg-img3
        background: url('../../../assets/images/cloud-hw/yuan3.png') no-repeat center/contain
      .bg-img4
        background: url('../../../assets/images/cloud-hw/yuan4.png') no-repeat center/contain
      .bg-img5
        background: url('../../../assets/images/cloud-hw/yuan5.png') no-repeat center/contain
      .bg-img6
        background: url('../../../assets/images/cloud-hw/yuan6.png') no-repeat center/contain
      .person
        position: absolute
        top: 50%
        left: 50%
        transform: translateX(-50%) translateY(-50%)
        font-size: 0.8em
        
    .desc
      margin: 1% 0 3%
      font-size: 0.7em
      font-weight: 700
    .percentage
      font-size: 0.6em
</style>